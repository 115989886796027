<template>
  <div class="text-center loader">
    <div style="position: relative; top: 40%; color:black !important">
      <div
        class="la-ball-pulse-sync la-2x icon-wrapper">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading3',
};
</script>

<style lang="scss" src="./loading3.scss" scoped></style>
